import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faSignOutAlt,
  faUser,
  faClock,
} from "@fortawesome/free-solid-svg-icons"; // Import faSignOutAlt and faUser
import HomeBackground from "../assets/homeBg.png";
import Logo from "../assets/mtmNexusLogo.png";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetAuthComponent } from "../redux/authSlice";
import { logoutAsync } from "../redux/authSlice";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in on component mount
    setIsLoggedIn(!!localStorage.getItem("token"));
    dispatch(resetAuthComponent());
  }, []);

  const handleClick = (type) => {
    if (isLoggedIn) {
      navigate("/AnalysisReport");
    } else {
      localStorage.setItem("SignFrom", type);
      navigate("/signin");
    }
  };

  const handleLogout = async () => {
    // try {
    //   const accessToken = localStorage.getItem("token");
    //   if (!accessToken) {
    //     throw new Error("Token is null");
    //   }

    dispatch(logoutAsync())
      .unwrap()
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("userID");
        localStorage.removeItem("email");
        toast.success("User signed out successfully");
        setIsLoggedIn(false); // Update state after logout
        navigate("/");
      })
      .catch((e) => toast.error("Cannot logout"));

    // if (response.error) {
    //   throw new Error(response.error.message || "Logout failed");
    // }

    //   localStorage.removeItem("token");
    //   localStorage.removeItem("userID");
    //   localStorage.removeItem("email");
    //   toast.success("User signed out successfully");
    //   setIsLoggedIn(false); // Update state after logout
    //   navigate("/");
    // } catch (error) {
    //   console.error("Failed to log out", error);
    //   toast.error("Logout failed. Please try again.");
    // }
  };

  return (
    <div className="bg-gray-50">
      {/* Header */}
      <header
        className="flex justify-between items-center px-6 py-4 bg-white shadow-md flex-wrap"
        style={{ boxShadow: "0 2px 2px #00000040" }}
      >
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="MTM Nexus Logo"
            className="h-16 md:w-32 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <nav className="flex space-x-4 order-last md:order-none mt-4 md:mt-0">
          <a href="#about" className="text-gray-900 hover:text-blue-600">
            About Us
          </a>
          <a href="#contact" className="text-gray-900 hover:text-blue-600">
            Contact Us
          </a>
        </nav>

        <div className="flex items-center space-x-4 order-last md:order-none mt-4 md:mt-0">
          {/* Social Media Icons */}
          <a
            href="https://www.linkedin.com/company/mtm-nexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 hover:text-blue-600"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/mtmnexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-400"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          {/* <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-300"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a> */}
        </div>

        <div className="flex items-center space-x-4 mt-4 md:mt-0">
          {/* Analysis History Button */}
          {isLoggedIn && (
            <button
              className="border border-blue-500 text-blue-500 px-4 py-1 md:py-2 rounded-lg hover:bg-blue-600 hover:text-white transition-all duration-200"
              onClick={() => navigate("/analysisHistory")}
            >
              <FontAwesomeIcon icon={faClock} />
              <span className="hidden md:inline ml-2">Analysis History</span>
            </button>
          )}

          {/* Sign In/Out Button */}
          <button
            onClick={() =>
              isLoggedIn ? handleLogout() : handleClick("userIcon")
            }
            className={`flex items-center px-4 py-2 rounded-xl ${
              isLoggedIn
                ? "bg-red-600 hover:bg-red-700 text-white"
                : "bg-[#007AFF] hover:bg-blue-600 text-white"
            }`}
          >
            <FontAwesomeIcon
              icon={isLoggedIn ? faSignOutAlt : faUser}
              className="h-5 w-5"
            />
            <span className="hidden md:inline ml-2">
              {isLoggedIn ? "Sign Out" : "Sign In/Sign Up"}
            </span>
          </button>
        </div>
      </header>

      {/* Main Content */}
      <div className="flex flex-col h-screen">
        <div
          className="flex flex-1 flex-col md:flex-row items-center justify-between p-6 md:p-12 relative bg-center md:bg-right bg-cover md:bg-contain mt-[2px]"
          style={{
            backgroundImage: `url(${HomeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "color",
          }}
        >
          {/* Text Content */}
          <div className="text-center md:text-left z-10 max-w-md w-full">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 leading-tight md:leading-snug tracking-wide">
              AI-Powered and Pharmacist Approved <br />
              <span className="text-[#007AFF]">MTM Analysis</span>
            </h1>
            <p className="text-lg mb-8 text-white md:text-[#0000008A] leading-relaxed tracking-normal">
              Revolutionizing Medication Therapy Management: Harnessing AI and
              Pharmacist Expertise for Personalized Patient Care
            </p>

            <button
              className="bg-[#007AFF] text-white px-6 py-3 rounded-3xl text-lg hover:bg-blue-600"
              style={{
                boxShadow: "0 4px 15px 0 rgba(0, 122, 255, 0.6)",
                transition: "box-shadow 0.3s ease",
              }}
              onClick={() => handleClick("startIcon")}
            >
              Start MTM Analysis
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <div id="about" className="flex flex-col h-[130vh] md:h-screen">
        <AboutUs />
      </div>

      {/* Contact Us Section */}
      <div id="contact" className="flex flex-col h-screen custom-height">
        <ContactUs />
      </div>

      {/* Footer */}
      <footer className="bg-[#007AFF] text-white text-center py-4 mt-2">
        <p>&copy; {new Date().getFullYear()} MTM Nexus. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
