// src/components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token"); // Adjust the key name as per your token storage

  return token ? children : <Navigate to="/signin" />;
};

export default ProtectedRoute;
