import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const modifiedUrl = `https://mtmnexus.com`;

// const modifiedUrl = `https://f273-2405-201-6004-90a7-d745-e81b-df51-bb7b.ngrok-free.app`;

export const loginAsyncGeneral = createAsyncThunk(
  "auth/login",
  async (credentials) => {
    try {
      const response = await fetch(`${modifiedUrl}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

const generalSlice = createSlice({
  name: "auth",
  initialState: {
    dataReset: null,
    errorReset: null,
    statusReset: "idle",
    loadingReset: false,
  },
  reducers: {
    resetAuthComponent(state) {
      state.userLogin = null;
      state.errorLogin = null;
      state.userSignUp = null;
      state.errorSignUp = null;
      state.dataForget = null;
      state.errorForget = null;
      state.dataReset = null;
      state.errorReset = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsyncGeneral.pending, (state) => {
        state.statusLogin = "loading";
        state.loadingLogin = true;
      })
      .addCase(loginAsyncGeneral.fulfilled, (state, action) => {
        console.log("loginAsync success", action);
        state.statusLogin = "succeeded";
        state.userLogin = action.payload;
        state.errorLogin = null;
        state.loadingLogin = false;
      })
      .addCase(loginAsyncGeneral.rejected, (state, action) => {
        console.log("loginAsync fail", action);
        state.statusLogin = "failed";
        state.userLogin = null;
        state.errorLogin = action.error.message || "An error occurred";
        state.loadingLogin = false;
      });
  },
});
export const { resetAuthComponent } = generalSlice.actions;
export default generalSlice.reducer;
