import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import ContactBackground from "../assets/contactBg.png";
import Phone from "../assets/phone.svg";
import Email from "../assets/email.svg";
import Address from "../assets/location.svg";

const ContactUs = () => {
  return (
    <div
      className="flex flex-1 flex-col md:flex-row items-center justify-between p-6 md:p-12 relative md:bg-right bg-contain md:bg-contain bg-pos-mobile-con"
      style={{
        backgroundImage: `url(${ContactBackground})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Contact Details */}
      <div className="text-center md:text-left z-10">
        <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900 leading-tight md:leading-snug tracking-wide">
          Contact Us
        </h1>

        {/* Phone */}
        {/* <div className="flex items-center gap-3 mb-4">
          <img src={Phone} className="w-6" alt="Phone" />
          <a
            href="tel:+1234567891"
            className="text-lg text-gray-700 hover:text-blue-500"
          >
            +123 456 78 91
          </a>
        </div> */}

        {/* Email */}
        <div className="flex items-center gap-3 mb-4">
          <img src={Email} className="w-6" alt="Email" />
          <a
            href="mailto:help@mtmnexus.com"
            className="text-lg text-gray-700 hover:text-blue-500"
          >
            help@mtmnexus.com
          </a>
        </div>

        {/* Address */}
        {/* <div className="flex items-center gap-3 mb-6">
          <img src={Address} className="w-6" alt="Address" />
          <span className="text-lg text-gray-700 text-left">
            Vivamus street Block C - Vestibulum Building - 3rd Floor
          </span>
        </div> */}

        {/* Social Media */}
        <div className="flex items-center gap-4">
          <a
            href="https://www.linkedin.com/company/mtm-nexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              size="2x"
              className="text-blue-700"
            />
          </a>
          <a
            href="https://www.instagram.com/mtmnexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              className="text-pink-500"
            />
          </a>
          {/* <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor"
          >
            <FontAwesomeIcon
              icon={faTwitter}
              size="2x"
              className="text-blue-400"
            />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
