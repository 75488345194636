import React from "react";
import AboutBackground from "../assets/aboutBg.png";

const AboutUs = () => {
  return (
    <div
      className="flex flex-1 flex-col md:flex-row-reverse items-center justify-between p-6 md:p-12 relative bg-center md:bg-left bg-cover md:bg-contain bg-pos-mobile"
      style={{
        backgroundImage: `url(${AboutBackground})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Text Content */}
      <div className="text-center md:text-left z-10 max-w-lg md:max-w-2xl w-full">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 text-gray-900 leading-tight md:leading-snug tracking-wide">
          Medication Therapy Management
        </h1>
        {/* <p className="text-lg mb-4 text-[#0000008A] leading-relaxed tracking-normal">
          As the founder of MTM Nexus, I am a pharmacist with over 10 years of
          experience, licensed in five states and dedicated to advancing
          Medication Therapy Management (MTM) through innovative AI-driven
          solutions.
        </p> */}
        <p className="text-lg mb-4 text-[#0000008A] leading-relaxed tracking-normal">
          At MTM Nexus, we harness artificial intelligence to deliver
          comprehensive MTM services, analyzing patient data to optimize
          medication regimens, improve adherence, and reduce costs.
        </p>
        <p className="text-lg mb-4 text-[#0000008A] leading-relaxed tracking-normal">
          Our platform seamlessly integrates with healthcare providers,
          insurance companies, and pharmacies, fostering an ecosystem that
          prioritizes both patient health and economic efficiency.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
