import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import BackgroundAuth from "../../assets/bgauth.svg";
import BackButton from "../../assets/back-button.svg";
import Logo from "../../assets/LogoS.png";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPasswordAsync,
  emailForReset,
  resetAuthComponent,
} from "../../redux/authSlice";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataForget, errorForget, loadingForget } = useSelector(
    (state) => state.auth
  );

  // Form validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  // Initial form values
  const initialValues = {
    email: "",
  };

  // Form submission handler
  const onSubmit = (values) => {
    localStorage.setItem("email", values?.email);
    dispatch(forgetPasswordAsync(values));
  };

  // Handle response and errors
  useEffect(() => {
    if (dataForget) {
      toast.success("Password reset OTP email sent.");
      dispatch(resetAuthComponent());
      navigate("/ResetPassword");
    }
    if (errorForget) {
      toast.error(errorForget);
      dispatch(resetAuthComponent());
    }
  }, [dataForget, errorForget]);

  return (
    <div
      className="flex justify-center items-center h-screen bg-[#4FA3FF] px-4"
      style={{
        backgroundImage: `url(${BackgroundAuth})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="bg-[#007AFF] rounded-xl shadow-md flex flex-col md:flex-row justify-between w-full max-w-4xl">
        <div className="flex flex-col items-center p-8 md:w-1/2">
          <img
            src={BackButton}
            alt="backbutton"
            className="cursor-pointer mb-4 self-start"
            onClick={() => navigate("/signin")}
          />
          <div className="flex-grow flex justify-center items-center">
            <img src={Logo} alt="Logo" className="w-64" />
          </div>
        </div>
        <div
          className="bg-white p-8 custom-radius md:rounded-r-xl w-full md:w-1/2 flex flex-col justify-center"
          style={{ minHeight: "400px" }}
        >
          <h2 className="text-2xl font-bold mb-6 text-left">Forget Password</h2>
          <p className="mb-4 text-sm text-[#718096]">
            Enter your email to reset your password.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-[#718096]"
                  >
                    E-mail
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#718096]"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                {loadingForget ? (
                  <div className="w-full py-2 px-4 bg-gray-400 text-white rounded-md text-center">
                    Loading...
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-[#007AFF] text-white rounded-md focus:outline-none ${
                      !(isValid && dirty) ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={!(isValid && dirty)}
                  >
                    Forget Password
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
