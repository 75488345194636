import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import BackgroundAuth from "../../assets/bgauth.svg";
import BackButton from "../../assets/back-button.svg";
import Logo from "../../assets/LogoS.png";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthComponent, signupAsync } from "../../redux/authSlice";
import { toast } from "react-toastify";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { userSignUp, errorSignUp, loadingSignUp } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (userSignUp) {
      toast.success("Signup successful!");
      navigate("/signin");
      dispatch(resetAuthComponent());
    } else if (errorSignUp) {
      toast.error(errorSignUp);
      dispatch(resetAuthComponent());
    }
  }, [userSignUp, errorSignUp]);
  // Form validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  // Initial form values
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  // Form submission handler
  const onSubmit = async (values) => {
    try {
      dispatch(signupAsync(values));
    } catch (error) {
      toast.error(error || "Signup failed. Please try again.");
    }
  };

  return (
    <div
      className="flex justify-center items-center h-screen bg-[#4FA3FF] px-4"
      style={{
        backgroundImage: `url(${BackgroundAuth})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="bg-[#007AFF] rounded-xl shadow-md flex flex-col md:flex-row justify-between w-full max-w-4xl">
        <div className="flex flex-col items-center p-8 md:w-1/2">
          <img
            src={BackButton}
            alt="backbutton"
            className="cursor-pointer mb-4 self-start"
            onClick={() => navigate("/signin")}
          />
          <div className="flex-grow flex justify-center items-center">
            <img src={Logo} alt="Logo" className="w-64" />
          </div>
        </div>
        <div className="bg-white p-8 custom-radius md:rounded-r-xl w-full md:w-1/2">
          <h2 className="text-2xl font-bold mb-6 text-left">Sign Up</h2>
          <p className="mb-4 text-sm text-[#718096]">
            I have an account?{" "}
            <span
              className="text-blue-500 underline cursor-pointer"
              onClick={() => navigate("/signin")}
            >
              Login
            </span>
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-[#718096]"
                  >
                    E-mail
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#718096]"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-4 relative">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-[#718096]"
                  >
                    Password
                  </label>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#718096]"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-10 cursor-pointer"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-6 relative">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-[#718096]"
                  >
                    Confirm Password
                  </label>
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#718096]"
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 top-10 cursor-pointer"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {loadingSignUp ? (
                  <div className="w-full py-2 px-4 bg-gray-400 text-white rounded-md text-center">
                    Loading...
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-[#007AFF] text-white rounded-md focus:outline-none ${
                      !(isValid && dirty) ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={!(isValid && dirty)}
                  >
                    Sign Up
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
