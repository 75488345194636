import React, { useEffect, useState } from "react";
import Logo from "../assets/mtmNexusLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faSignOutAlt,
  faUser,
  faEye,
  faFilePdf,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAuthComponent,
  fetchAnalysisHistoryAsync,
} from "../redux/authSlice";
import { toast } from "react-toastify";
import { logoutAsync } from "../redux/authSlice";

const AnalysisHistory = () => {
  const itemsPerPage = 5;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { analysisHistory, loadingAnalysisHistory, errorAnalysisHistory } =
    useSelector((state) => state.auth);
  const pdfLink = useSelector((state) => state.auth.pdfLink);

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem("token"));
    dispatch(resetAuthComponent());
    dispatch(fetchAnalysisHistoryAsync()).then((res) =>
      console.log("Analysis history response: ", res)
    );
  }, []);

  const handleViewReport = (pdfLink) => {
    localStorage.setItem("report", pdfLink);
    navigate(`/generatedReport`);
  };

  const handleLogout = async () => {
    try {
      const accessToken = localStorage.getItem("token");
      if (!accessToken) {
        throw new Error("Token is null");
      }

      const response = await dispatch(logoutAsync());
      if (response.error) {
        throw new Error(response.error.message || "Logout failed");
      }

      localStorage.removeItem("token");
      localStorage.removeItem("userID");
      localStorage.removeItem("email");
      toast.success("User signed out successfully");
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const handleClick = (type) => {
    if (isLoggedIn) {
      navigate("/AnalysisReport");
    } else {
      localStorage.setItem("SignFrom", type);
      navigate("/signin");
    }
  };

  const data = [
    { name: "aman", age: 35, date: "20 - 11 - 2024" },
    { name: "john", age: 42, date: "18 - 10 - 2024" },
    { name: "sara", age: 28, date: "15 - 09 - 2024" },
  ];
  // const data = [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = analysisHistory.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination controls
  const totalPages = Math.ceil(analysisHistory.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <header
        className="flex justify-between items-center px-6 py-4 bg-white shadow-md flex-wrap"
        style={{ boxShadow: "0 2px 2px #00000040" }}
      >
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="MTM Nexus Logo"
            className="h-16 md:w-32 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="flex items-center space-x-4 order-last md:order-none mt-4 md:mt-0 ml-5 md:ml-0">
          <a
            href="https://www.linkedin.com/company/mtm-nexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 hover:text-blue-600"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/mtmnexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-400"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          {/* <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-300"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a> */}
        </div>

        <div className="flex items-center space-x-4 mt-4 md:mt-0">
          {isLoggedIn && (
            <button
              className="border border-blue-500 text-blue-500 px-4 py-1 md:py-2 rounded-lg hover:bg-blue-600 hover:text-white transition-all duration-200"
              onClick={() => navigate("/analysisHistory")}
            >
              <FontAwesomeIcon icon={faClock} />
              <span className="hidden md:inline ml-2">Analysis History</span>
            </button>
          )}
          <button
            onClick={() =>
              isLoggedIn ? handleLogout() : handleClick("userIcon")
            }
            className={`flex items-center px-4 py-2 rounded-xl ${
              isLoggedIn
                ? "bg-red-600 hover:bg-red-700 text-white"
                : "bg-[#007AFF] hover:bg-blue-600 text-white"
            }`}
          >
            <FontAwesomeIcon
              icon={isLoggedIn ? faSignOutAlt : faUser}
              className="h-5 w-5"
            />
            <span className="hidden md:inline ml-2">
              {isLoggedIn ? "Sign Out" : "Sign In/Sign Up"}
            </span>
          </button>
        </div>
      </header>

      <div className="w-11/12 max-w-6xl mx-auto mt-6 p-4">
        <div className="flex flex-col flex-start gap-y-5 mb-4 w-full">
          <button
            onClick={() => navigate(-1)}
            className="bg-blue-500 text-white text-lg sm:text-2xl font-bold rounded-lg w-8 
            h-8 flex items-center justify-center hover:bg-blue-600 transition duration-200 ease-in-out"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>

          <h1 className="text-xl sm:text-2xl font-semibold text-[#007AFFB2]">
            Analysis History
          </h1>
        </div>

        <div className="bg-white shadow rounded-lg overflow-x-auto w-full">
          {loadingAnalysisHistory ? (
            <div className="p-4 text-center text-gray-600">
              Loading history...
            </div>
          ) : errorAnalysisHistory ? (
            <div className="p-4 text-center text-red-600">
              {errorAnalysisHistory}
            </div>
          ) : analysisHistory.length === 0 ? (
            <div className="p-4 text-center text-gray-600">No history</div>
          ) : (
            <table className="min-w-full text-center divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-6 py-3 text-gray-700 font-semibold">
                    Name
                  </th>
                  <th className="px-6 py-3 text-gray-700 font-semibold">Age</th>
                  <th className="px-6 py-3 text-gray-700 font-semibold">
                    Date
                  </th>
                  <th className="px-6 py-3 text-gray-700 font-semibold">
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-100 transition duration-150"
                  >
                    <td className="px-6 py-4 whitespace-nowrap flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className="text-red-500 mr-2"
                      />
                      <span className="font-medium">{item.name}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.age}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.date}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        className="text-gray-500 hover:text-gray-700 transition"
                        onClick={() => {
                          handleViewReport(item?.pdfLink);

                          //window.open(item.id)
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {totalPages > 1 && (
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className={`px-4 py-2 bg-blue-500 text-white rounded ${
                  currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Previous
              </button>
              <span className="font-semibold text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 bg-blue-500 text-white rounded ${
                  currentPage === totalPages
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalysisHistory;
