import React, { useEffect, useState } from "react";
import Logo from "../assets/mtmNexusLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFilePdf,
  faClock,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { resetAuthComponent, logoutAsync } from "../redux/authSlice";

const GeneratedReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pdfFile, setPdfFile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem("token"));
    dispatch(resetAuthComponent());
  }, []);

  // const pdfLink = useSelector((state) => state.auth.pdfLink);
  //console.log("{{{{{{{", pdfLink);

  const handleLogout = async () => {
    try {
      const accessToken = localStorage.getItem("token");
      if (!accessToken) {
        throw new Error("Token is null");
      }

      const response = await dispatch(logoutAsync());
      if (response.error) {
        throw new Error(response.error.message || "Logout failed");
      }

      localStorage.removeItem("token");
      localStorage.removeItem("userID");
      localStorage.removeItem("email");
      toast.success("User signed out successfully");
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const handleClick = (type) => {
    if (isLoggedIn) {
      navigate("/AnalysisReport");
    } else {
      localStorage.setItem("SignFrom", type);
      navigate("/signin");
    }
  };

  // useEffect(() => {
  //   const pdfLink = `${window.location.origin}/document.pdf`;
  //   localStorage.setItem("pdfLink", pdfLink);
  //   setPdfFile(pdfLink);
  // }, []);

  // useEffect(() => {
  //   dispatch(submitAnalysisReportAsync(reportData));
  // }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem("report")) {
      setPdfFile(localStorage.getItem("report"));
    }
  }, []);

  const handleDownloadPDF = () => {
    if (pdfFile) {
      const link = document.createElement("a");
      link.href = pdfFile;
      link.download = "Medical_Report.pdf"; // Specify the filename for the download
      link.target = "_blank";
      link.click(); // Trigger the download
    } else {
      console.error("PDF link not found");
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <header
        className="flex justify-between items-center px-6 py-4 bg-white shadow-md flex-wrap"
        style={{ boxShadow: "0 2px 2px #00000040" }}
      >
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="MTM Nexus Logo"
            className="h-16 md:w-32 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="flex items-center space-x-4 order-last md:order-none mt-4 md:mt-0 ml-5 md:ml-0">
          <a
            href="https://www.linkedin.com/company/mtm-nexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 hover:text-blue-600"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/mtmnexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-400"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          {/* <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-300"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a> */}
        </div>

        <div className="flex items-center space-x-4 mt-4 md:mt-0">
          {isLoggedIn && (
            <button
              className="border border-blue-500 text-blue-500 px-4 py-1 md:py-2 rounded-lg hover:bg-blue-600 hover:text-white transition-all duration-200"
              onClick={() => navigate("/analysisHistory")}
            >
              <FontAwesomeIcon icon={faClock} />
              <span className="hidden md:inline ml-2">Analysis History</span>
            </button>
          )}
          <button
            onClick={() =>
              isLoggedIn ? handleLogout() : handleClick("userIcon")
            }
            className={`flex items-center px-4 py-2 rounded-xl ${
              isLoggedIn
                ? "bg-red-600 hover:bg-red-700 text-white"
                : "bg-[#007AFF] hover:bg-blue-600 text-white"
            }`}
          >
            <FontAwesomeIcon
              icon={isLoggedIn ? faSignOutAlt : faUser}
              className="h-5 w-5"
            />
            <span className="hidden md:inline ml-2">
              {isLoggedIn ? "Sign Out" : "Sign In/Sign Up"}
            </span>
          </button>
        </div>
      </header>

      <div className="w-11/12 max-w-6xl mx-auto mt-6 p-4">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={() => navigate(-1)}
            className="bg-blue-500 text-white text-lg sm:text-2xl font-bold rounded-lg w-8 sm:w-10 h-8 sm:h-10 flex items-center justify-center hover:bg-blue-600 transition duration-200 ease-in-out"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            onClick={handleDownloadPDF}
            className="border border-blue-500 text-[#409BFF] px-4 py-2 rounded-lg hover:bg-blue-600 hover:text-white transition-all duration-200 flex items-center"
          >
            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
            Download PDF
          </button>
        </div>
        {console.log("pdf link", pdfFile, localStorage.getItem("report"))}
        <div className="bg-white shadow rounded-lg overflow-x-auto w-full p-4">
          {pdfFile ? (
            <iframe
              src={pdfFile}
              title="PDF Preview"
              width="100%"
              height="600px"
              style={{ border: "none" }}
              onLoad={() => console.log("PDF loaded")}
              onError={(e) => console.log("PDF failed to load", e)}
            />
          ) : (
            <div className="p-4 text-center text-gray-600">
              Loading PDF preview...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneratedReport;
