import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import BackgroundAuth from "../../assets/bgauth.svg";
import BackButton from "../../assets/back-button.svg";
import Logo from "../../assets/LogoS.png";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPasswordAsync,
  resetAuthComponent,
  resetPasswordAsync,
} from "../../redux/authSlice";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataReset, errorReset, loadingReset } = useSelector(
    (state) => state.auth
  );
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [timer, setTimer] = useState(180);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const emailForReset = useSelector((state) => state.auth.emailForReset);

  useEffect(() => {
    let interval;
    if (dataReset) {
      toast.success("Password reset successfully.");
      dispatch(resetAuthComponent());
      navigate("/signin");
    }
    if (errorReset) {
      toast.error(errorReset);
      dispatch(resetAuthComponent());
    }

    if (isResendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer, isResendDisabled, dataReset, errorReset, navigate]);

  const handleResendOtp = () => {
    const mail = localStorage.getItem("email");
    dispatch(forgetPasswordAsync({ email: mail }));
    setTimer(180);
    setIsResendDisabled(true);
    console.log("OTP resent");
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("OTP is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    otp: "",
    newPassword: "",
    confirmPassword: "",
  };

  const onSubmit = (values) => {
    const newResetData = {
      email: localStorage.getItem("email"),
      otp: values.otp,
      newPassword: values.newPassword,
    };
    dispatch(resetPasswordAsync(newResetData));
    console.log("new Reset Password Data", newResetData);
  };

  return (
    <div
      className="flex justify-center items-center h-screen bg-[#4FA3FF] px-4"
      style={{
        backgroundImage: `url(${BackgroundAuth})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="bg-[#007AFF] rounded-xl shadow-md flex flex-col md:flex-row justify-between w-full max-w-4xl">
        <div className="flex flex-col items-center p-8 md:w-1/2">
          <img
            src={BackButton}
            alt="backbutton"
            className="cursor-pointer mb-4 self-start"
            onClick={() => navigate("/signin")}
          />
          <div className="flex-grow flex justify-center items-center">
            <img src={Logo} alt="Logo" className="w-64" />
          </div>
        </div>
        <div
          className="bg-white p-8 custom-radius md:rounded-r-xl w-full md:w-1/2 flex flex-col justify-center"
          style={{ minHeight: "500px" }}
        >
          <h2 className="text-2xl font-bold mb-4 text-left">Reset Password</h2>
          <p className="text-sm text-gray-600 mb-4">
            OTP sent on registered email ID
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <div className="mb-4">
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium text-[#718096]"
                  >
                    Verify OTP
                  </label>
                  <Field
                    type="text"
                    name="otp"
                    id="otp"
                    className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#718096]"
                  />
                  <ErrorMessage
                    name="otp"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-6">
                  <button
                    type="button"
                    className={`text-sm underline ${
                      isResendDisabled
                        ? "text-gray-400"
                        : "text-blue-500 cursor-pointer"
                    }`}
                    onClick={handleResendOtp}
                    disabled={isResendDisabled}
                  >
                    {isResendDisabled
                      ? `Resend OTP in ${timer}s`
                      : "Resend OTP"}
                  </button>
                </div>

                <div className="mb-4 relative">
                  <label
                    htmlFor="newPassword"
                    className="block text-sm font-medium text-[#718096]"
                  >
                    New Password
                  </label>
                  <Field
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    id="newPassword"
                    className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#718096]"
                  />
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEye : faEyeSlash}
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className="absolute right-3 top-10 cursor-pointer"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-6 relative">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-[#718096]"
                  >
                    Confirm Password
                  </label>
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    className="mt-1 block w-full p-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-[#718096]"
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 top-10 cursor-pointer"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {loadingReset ? (
                  <div className="w-full py-2 px-4 bg-gray-400 text-white rounded-md text-center">
                    Loading...
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-[#007AFF] text-white rounded-md focus:outline-none ${
                      !(isValid && dirty) ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={!(isValid && dirty)}
                  >
                    Reset Password
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
