import React, { useEffect, useRef, useState } from "react";
import Logo from "../assets/mtmNexusLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faSignOutAlt,
  faUser,
  faMinus,
  faPlusCircle,
  faFileMedical,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAuthComponent,
  suggestionDiagnosisCodeAsync,
} from "../redux/authSlice";
import { toast } from "react-toastify";
import { submitAnalysisReportAsync } from "../redux/authSlice";
import AddIcon from "../assets/Add.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  suggestionAsync,
  logoutAsync,
  resetSuggestions,
} from "../redux/authSlice";

const AnalysisReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [medicationSuggestions, setMedicationSuggestions] = useState([]);
  const [diagnosisCodeSuggestions, setDiagnosisCodeSuggestions] = useState([]);
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const [activeInputIndexCode, setActiveInputIndexCode] = useState(null);

  const sampleData = [
    "Aspi-Rub",
    "Aspi-Cor",
    "Aspi-Cor Pill",
    "Aspi-Rub Topical Product",
    "Aspi-Cor Oral Product",
    "aspirin Delayed Release Oral Tablet [Aspi-Cor]",
    "aspirin 81 MG [Aspi-Cor]",
    "Aspi-Rub 230 MG/ML Topical Spray",
    "methyl salicylate Topical Spray [Aspi-Rub]",
    "Aspi-Cor 81 MG Delayed Release Oral Tablet",
    "Aspi-Cor 81 MG Delayed Release Oral Tablet",
    "aspirin 81 MG Delayed Release Oral Tablet [Aspi-Cor]",
    "ASPIRIN 81 mg ORAL TABLET, DELAYED RELEASE [Aspi-Cor] ASPIRIN 81 mg ORAL TABLET, DELAYED RELEASE [Aspi-Cor]",
    "ASA 81 MG Delayed Release Oral Tablet [Aspi-Cor]",
  ];

  const { suggestionData, DiagnosisCodeData } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    // Check if the user is logged in on component mount
    setIsLoggedIn(!!localStorage.getItem("token"));
    dispatch(resetAuthComponent());
  }, []);

  useEffect(() => {
    if (suggestionData) {
      setMedicationSuggestions(suggestionData);
    }
  }, [suggestionData]);

  useEffect(() => {
    if (DiagnosisCodeData) {
      setDiagnosisCodeSuggestions(DiagnosisCodeData);
    }
  }, [DiagnosisCodeData]);

  const debounceTimer = useRef(null);

  const handleSuggestion = (value, index) => {
    clearTimeout(debounceTimer.current); // Clear the previous timer
    debounceTimer.current = setTimeout(() => {
      if (value.length >= 3) {
        dispatch(suggestionAsync(value));
      } else {
        dispatch(resetSuggestions());
      }
      setActiveInputIndex(index);
    }, 300); // Debounce time in milliseconds
  };
  // const handleSuggestion = (value, index) => {
  //   clearTimeout(debounceTimer.current);
  //   debounceTimer.current = setTimeout(() => {
  //     if (value.length >= 3) {
  //       setMedicationSuggestions(sampleData); // Mocking suggestions for testing
  //     } else {
  //       setMedicationSuggestions([]);
  //     }
  //     setActiveInputIndex(index); // Set active input field for suggestions
  //   }, 300);
  // };

  // const handleDiagnosisCodeSuggestions = (value, index) => {
  //   clearTimeout(debounceTimer.current);
  //   debounceTimer.current = setTimeout(() => {
  //     if (value.length >= 3) {
  //       setDiagnosisCodeSuggestions(sampleData); // Mocking suggestions for testing
  //     } else {
  //       setDiagnosisCodeSuggestions([]);
  //     }
  //     setActiveInputIndexCode(index); // Set active input field for suggestions
  //   }, 300);
  // };

  const handleDiagnosisCodeSuggestions = (value, index) => {
    clearTimeout(debounceTimer.current); // Clear the previous timer
    debounceTimer.current = setTimeout(() => {
      if (value.length > 0) {
        dispatch(suggestionDiagnosisCodeAsync(value));
      } else {
        dispatch(resetSuggestions());
      }
      setActiveInputIndexCode(index);
    }, 300); // Debounce time in milliseconds
  };

  const handleBlur = () => {
    dispatch(resetSuggestions());
    setActiveInputIndex(null); // Reset active input field
  };
  const handleBlurCode = () => {
    dispatch(resetSuggestions());
    setActiveInputIndexCode(null); // Reset active input field
  };
  const handleLogout = async () => {
    try {
      const accessToken = localStorage.getItem("token");
      if (!accessToken) {
        throw new Error("Token is null");
      }

      const response = await dispatch(logoutAsync());
      if (response.error) {
        throw new Error(response.error.message || "Logout failed");
      }

      localStorage.removeItem("token");
      localStorage.removeItem("userID");
      localStorage.removeItem("email");
      toast.success("User signed out successfully");
      setIsLoggedIn(false); // Update state after logout
      navigate("/");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };
  const handleClick = (type) => {
    if (isLoggedIn) {
      navigate("/AnalysisReport");
    } else {
      localStorage.setItem("SignFrom", type);
      navigate("/signin");
    }
  };
  const parseHeight = (height) => {
    const regex = /^(\d+)'(\d+)"?$/;
    const match = height.match(regex);
    if (match) {
      const feet = parseInt(match[1], 10);
      const inches = parseInt(match[2], 10);
      return feet * 12 + inches; // Convert to total inches
    }
    return height;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      age: "",
      weight: "",
      weightUnit: "kg",
      height: "",
      heightUnit: "cm",
      allergies: [""],
      symptoms: [""],
      medications: [
        { medication: "", dose: "", frequency: "", Diagnosis_Code: "" },
      ],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      gender: Yup.string().required("Gender is required"),
      age: Yup.number()
        .required("Age is required")
        .max(100, "Age cannot exceed 100 years")
        .typeError("Age must be a number"),
      weight: Yup.number()
        .required("Weight is required")
        .typeError("Weight must be a number")
        .when("weightUnit", {
          is: "kg",
          then: (schema) => schema.max(300, "Weight cannot exceed 300 kg"),
          otherwise: (schema) =>
            schema.max(661, "Weight cannot exceed 661 lbs"),
        }),
      height: Yup.string()
        .required("Height is required")
        .when("heightUnit", {
          is: "cm",
          then: (schema) =>
            schema.matches(/^\d+$/, "Height must be a valid number in cm"),
          otherwise: (schema) => schema, // Allows standard input when heightUnit is 'm'
        })
        .when("heightUnit", {
          is: "ft",
          then: (schema) =>
            schema.test(
              "isValidHeightInFt",
              "Enter height as feet'inches (e.g., 5'11)",
              (value) => /^(\d{1,2})'(\d{1,2})?$/.test(value)
            ),
        })
        .when("heightUnit", {
          is: "m",
          then: (schema) =>
            schema.matches(
              /^\d+(\.\d{1,2})?$/,
              "Height must be a valid number in meters"
            ),
        }),
      allergies: Yup.array().of(Yup.string()),
      symptoms: Yup.array().of(Yup.string()),
      medications: Yup.array().of(
        Yup.object().shape({
          medication: Yup.string()
            .required("Medication is required")
            .min(3, "Minimum 3 characters are required"),
          dose: Yup.string().required("Dose is required"),
          frequency: Yup.string().required("Frequency is required"),
          Diagnosis_Code: Yup.string().required("Diagnosis is required"),
        })
      ),
    }),

    onSubmit: async (values) => {
      const parsedHeight = parseHeight(values.height);
      const userId = localStorage.getItem("userID");
      if (!userId) {
        toast.error("User not logged in.");
        return;
      }

      setIsSubmitting(true); // Start loading
      const combinedData = {
        ...values,
        height: `${parsedHeight} ${values.heightUnit}`,
        // height: parsedHeight,
        weight: `${values.weight} ${values.weightUnit}`,
      };
      delete combinedData.heightUnit;
      delete combinedData.weightUnit;

      // try {
      dispatch(submitAnalysisReportAsync({ ...combinedData, userId }))
        .then((res) => {
          console.log(res);
          if (res.meta.requestStatus === "fulfilled") {
            navigate("/generatedReport");
            toast.success("Analysis report submitted successfully!");
          }
        })
        .catch((e) => {
          console.log(e);
          setIsSubmitting(false);
          toast.error(e.message || "Failed to submit report");
        })
        .finally(() => {
          setTimeout(() => {
            setIsSubmitting(false);
          }, 3000);
        });
    },
  });

  return (
    <div className="bg-gray-50 min-h-screen">
      <header
        className="flex justify-between items-center px-6 py-4 bg-white shadow-md flex-wrap"
        style={{ boxShadow: "0 2px 2px #00000040" }}
      >
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="MTM Nexus Logo"
            className="h-16 md:w-32 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="flex items-center space-x-4 order-last md:order-none mt-4 md:mt-0 ml-5 md:ml-0">
          {/* Social Media Icons */}
          <a
            href="https://www.linkedin.com/company/mtm-nexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 hover:text-blue-600"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/mtmnexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-400"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          {/* <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-300"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a> */}
        </div>

        <div className="flex items-center space-x-4 mt-4 md:mt-0">
          {/* Analysis History Button */}
          {isLoggedIn && (
            <button
              className="border border-blue-500 text-blue-500 px-4 py-1 md:py-2 rounded-lg hover:bg-blue-600 hover:text-white transition-all duration-200"
              onClick={() => navigate("/analysisHistory")}
            >
              <FontAwesomeIcon icon={faClock} />
              <span className="hidden md:inline ml-2">Analysis History</span>
            </button>
          )}

          {/* Sign In/Out Button */}
          <button
            onClick={() =>
              isLoggedIn ? handleLogout() : handleClick("userIcon")
            }
            className={`flex items-center px-4 py-2 rounded-xl ${
              isLoggedIn
                ? "bg-red-600 hover:bg-red-700 text-white"
                : "bg-[#007AFF] hover:bg-blue-600 text-white"
            }`}
          >
            <FontAwesomeIcon
              icon={isLoggedIn ? faSignOutAlt : faUser}
              className="h-5 w-5"
            />
            <span className="hidden md:inline ml-2">
              {isLoggedIn ? "Sign Out" : "Sign In/Sign Up"}
            </span>
          </button>
        </div>
      </header>

      <div className="text-center mt-6 sm:mt-10 px-4">
        <h1
          className="text-2xl sm:text-4xl underline font-extrabold text-[#007AFF] mb-2"
          style={{ letterSpacing: "0.1em" }}
        >
          MTM Analysis
        </h1>
        <p className="text-[#007AFFB2] text-base sm:text-lg my-8">
          Your Medications, Our Insights – Let’s Optimize Together
        </p>
      </div>

      <div
        className="max-w-6xl mx-auto p-4 sm:p-8 mt-4 sm:mt-8 bg-white rounded-lg shadow-lg"
        style={{ width: "90%", marginBottom: "2rem" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div>
            <h2 className="text-xl sm:text-2xl font-semibold text-[#007AFF] mb-4">
              PATIENT DETAILS :
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <label className="block text-sm font-semibold text-black">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Type patient name"
                  {...formik.getFieldProps("name")}
                  className="w-full text-sm mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="text-red-500 text-xs ml-1 mt-1">
                    {formik.errors.name}
                  </p>
                ) : null}
              </div>
              <div>
                <label className="block text-sm font-semibold text-black">
                  Gender
                </label>
                <select
                  name="gender"
                  {...formik.getFieldProps("gender")}
                  className="w-full text-sm mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {formik.touched.gender && formik.errors.gender ? (
                  <p className="text-red-500 text-xs ml-1 mt-1">
                    {formik.errors.gender}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6 mt-4">
              <div>
                <label className="block text-sm font-semibold text-black">
                  Age
                </label>
                <input
                  type="number"
                  name="age"
                  placeholder="Type patient age"
                  {...formik.getFieldProps("age")}
                  className="w-full text-sm mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                />
                {formik.touched.age && formik.errors.age ? (
                  <p className="text-red-500 text-xs ml-1 mt-1">
                    {formik.errors.age}
                  </p>
                ) : null}
              </div>
              <div>
                <label className="block text-sm font-semibold text-black">
                  Weight
                </label>
                <div className="flex">
                  <input
                    type="number"
                    name="weight"
                    placeholder="Type patient weight"
                    {...formik.getFieldProps("weight")}
                    className="w-full text-sm mt-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:border-blue-600"
                  />
                  <select
                    name="weightUnit"
                    {...formik.getFieldProps("weightUnit")}
                    className="mt-1 p-2 border border-gray-300 rounded-r-lg focus:outline-none focus:border-blue-600"
                  >
                    <option value="kg">kg</option>
                    <option value="lbs">lbs</option>
                  </select>
                </div>
                {formik.touched.weight && formik.errors.weight ? (
                  <p className="text-red-500 text-xs ml-1 mt-1">
                    {formik.errors.weight}
                  </p>
                ) : null}
              </div>
              <div>
                <label className="block text-sm font-semibold text-black">
                  Height
                </label>
                <div className="flex">
                  <input
                    type="text"
                    name="height"
                    placeholder="Type patient height"
                    {...formik.getFieldProps("height")}
                    className="w-full text-sm mt-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:border-blue-600"
                  />
                  <select
                    name="heightUnit"
                    {...formik.getFieldProps("heightUnit")}
                    className="mt-1 p-2 border border-gray-300 rounded-r-lg focus:outline-none focus:border-blue-600"
                  >
                    <option value="cm">cm</option>
                    <option value="ft">ft</option>
                    <option value="m">m</option>
                  </select>
                </div>
                {formik.touched.height && formik.errors.height ? (
                  <p className="text-red-500 text-xs ml-1 mt-1">
                    {formik.errors.height}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="mt-4">
            <label className="block text-sm font-semibold text-black">
              Diagnosis
            </label>
            {formik.values.diagnosis.map((diag, index) => (
              <div key={index} className="mt-4 relative">
                {formik.values.diagnosis.length > 1 && (
                  <button
                    type="button"
                    onClick={() =>
                      formik.setFieldValue(
                        "diagnosis",
                        formik.values.diagnosis.filter((_, i) => i !== index)
                      )
                    }
                    className="absolute -top-2 -left-2 w-5 h-5 flex items-center justify-center bg-red-500 text-white rounded-full"
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                )}
                <div className="flex items-center">
                  <input
                    type="text"
                    name={`diagnosis[${index}]`}
                    placeholder="Type patient diagnosis"
                    {...formik.getFieldProps(`diagnosis[${index}]`)}
                    className="w-full md:w-[40%] text-sm p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                  />
                  {index === formik.values.diagnosis.length - 1 && (
                    <button
                      type="button"
                      onClick={() =>
                        formik.setFieldValue("diagnosis", [
                          ...formik.values.diagnosis,
                          "",
                        ])
                      }
                      className="ml-2 text-blue-500 font-semibold border border-blue-500 px-2 py-1 rounded-lg hover:bg-blue-100"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </button>
                  )}
                </div>
                {formik.touched.diagnosis &&
                formik.errors.diagnosis &&
                formik.errors.diagnosis[index] ? (
                  <div className="mt-1">
                    <p className="text-red-500 text-xs ml-1 mt-1">
                      {formik.errors.diagnosis[index]}
                    </p>
                  </div>
                ) : null}
              </div>
            ))}
          </div> */}

          <div className="mt-4">
            <label className="block text-sm font-semibold text-black">
              Allergies
            </label>
            {formik.values.allergies.map((allergy, index) => (
              <div key={index} className="mt-4 relative">
                {formik.values.allergies.length > 1 && (
                  <button
                    type="button"
                    onClick={() =>
                      formik.setFieldValue(
                        "allergies",
                        formik.values.allergies.filter((_, i) => i !== index)
                      )
                    }
                    className="absolute -top-2 -left-2 w-5 h-5 flex items-center justify-center bg-red-500 text-white rounded-full"
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                )}
                <div className="flex items-center">
                  <input
                    type="text"
                    name={`allergies[${index}]`}
                    placeholder="Type patient allergy"
                    {...formik.getFieldProps(`allergies[${index}]`)}
                    className="w-full md:w-[40%] text-sm p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                  />
                  {index === formik.values.allergies.length - 1 && (
                    <button
                      type="button"
                      onClick={() =>
                        formik.setFieldValue("allergies", [
                          ...formik.values.allergies,
                          "",
                        ])
                      }
                      className="ml-2 text-blue-500 font-semibold border border-blue-500 px-2 py-1 rounded-lg hover:bg-blue-100"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4">
            <label className="block text-sm font-semibold text-black">
              Symptoms
            </label>
            {formik.values.symptoms.map((symptom, index) => (
              <div key={index} className="mt-4 relative">
                {formik.values.symptoms.length > 1 && (
                  <button
                    type="button"
                    onClick={() =>
                      formik.setFieldValue(
                        "symptoms",
                        formik.values.symptoms.filter((_, i) => i !== index)
                      )
                    }
                    className="absolute -top-2 -left-2 w-5 h-5 flex items-center justify-center bg-red-500 text-white rounded-full"
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                )}
                <div className="flex items-center">
                  <input
                    type="text"
                    name={`symptoms[${index}]`}
                    placeholder="Type patient symptom"
                    {...formik.getFieldProps(`symptoms[${index}]`)}
                    className="w-full md:w-[40%] text-sm p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                  />
                  {index === formik.values.symptoms.length - 1 && (
                    <button
                      type="button"
                      onClick={() =>
                        formik.setFieldValue("symptoms", [
                          ...formik.values.symptoms,
                          "",
                        ])
                      }
                      className="ml-2 text-blue-500 font-semibold border border-blue-500 px-2 py-1 rounded-lg hover:bg-blue-100"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-10">
            <div className="flex justify-between items-center">
              <h2
                className="text-xl sm:text-2xl font-semibold text-black"
                style={{ borderRadius: "0.25rem" }}
              >
                Add Medications
              </h2>
            </div>
            {formik.values.medications.map((med, index) => (
              <div
                key={index}
                className="relative grid grid-cols-1 sm:grid-cols-4 gap-4 sm:gap-6 mt-4 p-4 border border-gray-300 rounded-lg shadow-md"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                {formik.values.medications.length > 1 && (
                  <button
                    type="button"
                    onClick={() =>
                      formik.setFieldValue(
                        "medications",
                        formik.values.medications.filter((_, i) => i !== index)
                      )
                    }
                    className="absolute top-2 right-2 w-5 h-5 flex items-center justify-center bg-red-500 text-white rounded-full"
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                )}

                <div className="relative">
                  <label className="block text-sm font-semibold text-black mb-1">
                    Medication
                  </label>
                  <input
                    type="text"
                    placeholder="Medication"
                    name={`medications[${index}].medication`}
                    {...formik.getFieldProps(
                      `medications[${index}].medication`
                    )}
                    ref={(el) => (inputRef.current[index] = el)} // Set ref for each medication input
                    className="w-full text-sm p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSuggestion(e.target.value, index); // Call handleSuggestion on input change
                    }}
                    onFocus={() => setActiveInputIndex(index)}
                    onBlur={handleBlur}
                  />
                  {formik.touched.medications &&
                  formik.errors.medications &&
                  formik.errors.medications[index]?.medication ? (
                    <p className="text-red-500 text-xs ml-1 mt-1">
                      {formik.errors.medications[index].medication}
                    </p>
                  ) : null}
                  {activeInputIndex === index &&
                    medicationSuggestions.length > 0 && (
                      <ul
                        className="absolute bg-white border border-gray-300 rounded-lg mt-1 shadow-lg"
                        style={{
                          top: "100%",
                          left: 0,
                          width:
                            inputRef.current[activeInputIndex]?.offsetWidth ||
                            "100%",
                          maxHeight: "200px",
                          overflowY: "auto",
                          zIndex: 10,
                        }}
                      >
                        {medicationSuggestions.map(
                          (suggestion, suggestionIndex) => (
                            <li
                              key={suggestionIndex}
                              onMouseDown={(e) => e.preventDefault()} // Prevents blur event when clicking on suggestion
                              onClick={() => {
                                formik.setFieldValue(
                                  `medications[${activeInputIndex}].medication`,
                                  suggestion?.name
                                );
                                dispatch(
                                  suggestionDiagnosisCodeAsync(suggestion?.name)
                                );
                                dispatch(resetSuggestions());
                                setActiveInputIndex(null);
                              }}
                              className="p-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-200"
                            >
                              {suggestion?.name}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                </div>

                <div className="relative">
                  <label className="block text-sm font-semibold text-black mb-1">
                    Dose
                  </label>
                  <input
                    type="text"
                    name={`medications[${index}].dose`}
                    placeholder="Dose"
                    {...formik.getFieldProps(`medications[${index}].dose`)}
                    onFocus={() => setActiveInputIndexCode(index)}
                    onBlur={handleBlurCode}
                    className="w-full text-sm p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                  />
                  {formik.touched.medications &&
                  formik.errors.medications &&
                  formik.errors.medications[index]?.dose ? (
                    <p className="text-red-500 text-xs ml-1 mt-1">
                      {formik.errors.medications[index].dose}
                    </p>
                  ) : null}
                  {activeInputIndexCode === index &&
                    diagnosisCodeSuggestions.length > 0 && (
                      <ul
                        className="absolute bg-white border border-gray-300 rounded-lg mt-1 shadow-lg"
                        style={{
                          top: "100%",
                          left: 0,
                          width:
                            inputRef.current[activeInputIndexCode]
                              ?.offsetWidth || "100%",
                          maxHeight: "200px",
                          overflowY: "auto",
                          zIndex: 10,
                        }}
                      >
                        {diagnosisCodeSuggestions.map(
                          (suggestion, suggestionIndex) => (
                            <li
                              key={suggestionIndex}
                              onMouseDown={(e) => e.preventDefault()} // Prevents blur event when clicking on suggestion
                              onClick={() => {
                                formik.setFieldValue(
                                  `medications[${activeInputIndexCode}].dose`,
                                  suggestion?.name
                                );
                                dispatch(resetSuggestions());
                                setActiveInputIndexCode(null);
                              }}
                              className="p-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-200"
                            >
                              {suggestion?.name}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                </div>
                <div>
                  <label className="block text-sm font-semibold text-black mb-1">
                    Frequency
                  </label>
                  <input
                    type="text"
                    name={`medications[${index}].frequency`}
                    placeholder="Frequency"
                    {...formik.getFieldProps(`medications[${index}].frequency`)}
                    className="w-full text-sm p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                  />
                  {formik.touched.medications &&
                  formik.errors.medications &&
                  formik.errors.medications[index]?.frequency ? (
                    <p className="text-red-500 text-xs ml-1 mt-1">
                      {formik.errors.medications[index].frequency}
                    </p>
                  ) : null}
                </div>
                <div className="relative">
                  <label className="block text-sm font-semibold text-black mb-1">
                    Diagnosis
                  </label>
                  <div className="flex justify-start items-center gap-2">
                    <input
                      type="text"
                      name={`medications[${index}].Diagnosis_Code`}
                      ref={(el) => (inputRef.current[index] = el)}
                      placeholder="Diagnosis"
                      {...formik.getFieldProps(
                        `medications[${index}].Diagnosis_Code`
                      )}
                      className="w-full text-sm p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-600"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        formik.setFieldValue("medications", [
                          ...formik.values.medications,
                          {
                            medication: "",
                            dose: "",
                            frequency: "",
                            Diagnosis_Code: "",
                          },
                        ]);
                        setMedicationSuggestions([]);
                        setDiagnosisCodeSuggestions([]);
                      }}
                      className="text-blue-500 font-semibold border border-blue-500 px-2 py-1 rounded-lg hover:bg-blue-100 ml-2"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} className="" />
                    </button>
                  </div>
                  {formik.touched.medications &&
                  formik.errors.medications &&
                  formik.errors.medications[index]?.Diagnosis_Code ? (
                    <p className="text-red-500 text-xs ml-1 mt-1">
                      {formik.errors.medications[index].Diagnosis_Code}
                    </p>
                  ) : null}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 flex justify-center">
            {isSubmitting ? (
              <div className="mt-8 w-full sm:w-1/3 flex justify-center py-2 rounded-lg text-lg font-normal transition-all duration-200 bg-gray-400 text-white text-center">
                Generating report...
              </div>
            ) : (
              <button
                type="submit"
                // className={`mt-8 w-full sm:w-1/3 flex justify-center py-2 rounded-lg text-lg font-normal transition-all duration-200 ${
                //   formik.isValid && !isSubmitting
                //     ? "bg-[#007AFF] hover:bg-blue-600 text-white"
                //     : "bg-gray-400 cursor-not-allowed text-white"
                // }`}
                className={`mt-8 w-full sm:w-1/2 md:w-1/2 flex justify-center items-center gap-x-2 py-2 rounded-lg text-lg font-normal transition-all duration-200 bg-[#007AFF]
                   text-white focus:outline-none whitespace-nowrap ${
                     formik.isValid && !isSubmitting
                       ? "hover:bg-blue-600"
                       : "opacity-50 cursor-not-allowed"
                   }`}
                disabled={!formik.isValid || isSubmitting}
              >
                <FontAwesomeIcon icon={faFileMedical} />
                {"Generate Analysis Report"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AnalysisReport;
