// In reducers.js
import { combineReducers } from "redux";
import authSlice from "./authSlice";
import GeneralSlice from "./GeneralSlice";
// Import your reducers here

const rootReducer = combineReducers({
  // Your reducers
  auth: authSlice,
  general: GeneralSlice,
});

export default rootReducer;
